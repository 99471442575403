var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"pa-3"},[_c('data-table',{ref:"dataTable",attrs:{"url":("stocks/" + _vm.ticker + "/dividends"),"item-key":"exDate","headers":_vm.headers,"table-params":_vm.tableParams},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('edit-dividend-modal',{attrs:{"is-open-modal":_vm.isOpenModal,"fields-dividend":_vm.fieldsDividend,"period-types":_vm.periodTypes,"statuses":_vm.statuses},on:{"change-field":_vm.changeField,"close":_vm.resetDividendFields,"save":_vm.saveDividend}})]},proxy:true},{key:"item.buyBefore",fn:function(ref){
var dividend = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("date")(dividend.buyBefore))+" ")]}},{key:"item.recordDate",fn:function(ref){
var dividend = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("date")(dividend.recordDate))+" ")]}},{key:"item.paymentDate",fn:function(ref){
var dividend = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("date")(dividend.paymentDate))+" ")]}},{key:"item.amount",fn:function(ref){
var dividend = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("number")(dividend.amount,{ fullPrecision: true }))+" ")]}},{key:"default",fn:function(ref){
var dividend = ref.item;
return [_vm._v(" "+_vm._s(dividend.currency)+" ")]}},{key:"item.frequency",fn:function(ref){
var dividend = ref.item;
return [_vm._v(" "+_vm._s(_vm.dividendPeriod(dividend.frequency))+" ")]}},{key:"item.stockPrice",fn:function(ref){
var dividend = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("number")(dividend.stockPrice,{ fullPrecision: true }))+" ")]}},{key:"item.status",fn:function(ref){
var dividend = ref.item;
return [_vm._v(" "+_vm._s(_vm.getStatusHint(dividend.status))+" ")]}},{key:"item.actions",fn:function(ref){
var dividend = ref.item;
return [_c('div',{staticClass:"d-flex"},[_c('v-btn',{attrs:{"icon":"","color":"accent"},on:{"click":function($event){return _vm.openEditedDividend(dividend)}}},[_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-pencil")])],1),_c('v-btn',{attrs:{"icon":"","color":"error"},on:{"click":function($event){return _vm.deleteDividend(dividend)}}},[_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-delete")])],1)],1)]}}],null,true)}),_c('v-divider',{staticClass:"my-6"}),_c('div',{staticClass:"text-h6 mb-3"},[_vm._v("Новый дивиденд")]),_c('create-dividend-form',{attrs:{"ticker":_vm.ticker,"period-types":_vm.periodTypes,"statuses":_vm.statuses}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }