<template>
  <v-row class="props-list">
    <v-col md="2">Тикер</v-col>
    <v-col md="10">
      <div>{{ stock.ticker }}</div>
    </v-col>

    <v-col md="2">Название акции</v-col>
    <v-col md="10">
      <v-text-field :value="stock.name" label="Название акции" @input="setField('name', $event)" />
    </v-col>

    <v-col md="2">Компания</v-col>
    <v-col md="10">
      <v-text-field :value="stock.company" label="Компания" @input="setField('company', $event)" />
    </v-col>

    <v-col md="2">Статус</v-col>
    <v-col md="10">
      <stocks-status :status="stock.status.value" @change="setField('status.value', $event)" />

      <template v-if="stock.status.value === 'ticker_changed'">
        <v-text-field
          :value="stock.status.newTicker"
          label="Новый тикер"
          @input="setField('status.newTicker', $event)"
        />
      </template>
    </v-col>

    <v-col md="2">Страна</v-col>
    <v-col md="10">
      <stocks-country :country="stock.country" @change="setField('country', $event)" />
    </v-col>

    <v-col md="2">Описание</v-col>
    <v-col md="10">
      <v-chip
        v-for="lang of descriptionLanguages"
        :key="lang"
        :color="lang === currentLang ? 'primary' : ''"
        class="mr-4"
        @click="currentLang = lang"
      >
        {{ lang.toUpperCase() }}
      </v-chip>

      <wysiwyg-editor
        class="mt-4"
        :value="stock.description[currentLang]"
        @input="setField(`description.${currentLang}`, $event)"
      />
    </v-col>

    <v-col md="2"> Описание mobile-friendly </v-col>

    <v-col md="10">
      <v-chip
        v-for="mobileLang of mobileDescriptionLanguages"
        :key="mobileLang"
        :color="mobileLang === currentMobileLang ? 'primary' : ''"
        class="mr-4"
        @click="currentMobileLang = mobileLang"
      >
        {{ mobileLang.toUpperCase() }}
      </v-chip>

      <v-textarea
        class="mt-4"
        :value="stock.mobileDescription[currentMobileLang]"
        dense
        @input="setField(`mobileDescription.${currentMobileLang}`, $event)"
      />
    </v-col>

    <v-col md="2">Тип акции</v-col>
    <v-col md="10">
      <v-select
        :value="stock.type"
        :items="typeStocks"
        label="Тип акции"
        item-value="uid"
        item-text="name"
        @input="setField('type', $event)"
      />
    </v-col>

    <v-col md="2">Валюта</v-col>
    <v-col md="10">{{ stock.currency }}</v-col>

    <v-col md="2">Биржа</v-col>
    <v-col md="10">
      <v-select
        :value="stock.exchange"
        :items="exchanges"
        label="Биржа"
        item-value="mic"
        item-text="name"
        @input="setField('exchange', $event)"
      />
    </v-col>

    <v-col md="2">Генеральный директор</v-col>
    <v-col md="10">
      <v-text-field :value="stock.ceo" label="Генеральный директор" @input="setField('ceo', $event)" />
    </v-col>

    <v-col md="2"> Адресс </v-col>
    <v-col md="10">
      <v-text-field :value="stock.address.address" label="Адрес" @input="setField('address.address', $event)" />
      <v-text-field :value="stock.address.city" label="Город" @input="setField('address.city', $event)" />
      <v-text-field :value="stock.address.country" label="Страна" @input="setField('address.country', $event)" />
    </v-col>

    <v-col md="2">Сотрудников</v-col>
    <v-col md="10">
      <v-text-field :value="stock.employees" label="Сотрудников" @input="setField('employees', $event)" />
    </v-col>

    <v-col md="2">Официальный сайт</v-col>
    <v-col md="10">
      <v-text-field :value="stock.site" label="Официальный сайт" @input="setField('site', $event)" />
    </v-col>

    <v-col md="2">Сектор</v-col>
    <v-col md="10">
      <stocks-sector :sector="stock.sector" @change="setField('sector', $event)" />
    </v-col>

    <v-col md="2">Количестве акций в обращении</v-col>
    <v-col md="10">
      <v-text-field
        :value="stock.statistic.sharesOutstanding"
        label="Кол-во акций в обращении"
        dense
        @input="setField(`statistic.sharesOutstanding`, $event)"
      />
    </v-col>

    <v-col md="2">Дата IPO</v-col>
    <v-col md="10">
      <date-picker :value="stock.statistic.ipoDate" label="Дата IPO" @input="setField('statistic.ipoDate', $event)" />
    </v-col>

    <v-col md="2">Последний сплит</v-col>
    <v-col v-if="split" md="10">{{ split.date | date }}</v-col>
    <v-col v-else md="10">—</v-col>

    <v-col md="2"> Пропорция сплита </v-col>
    <v-col md="10">{{ split ? `${split.from}:${split.to}` : "—" }}</v-col>

    <v-col md="3">
      <v-btn v-if="!isChangeTicker" type="submit" color="primary mr-4" @click="isChangeTicker = true">
        Замена тикера
      </v-btn>

      <div v-else>
        <v-select
          :items="stocks"
          item-value="ticker"
          item-text="ticker"
          label="Новый тикер"
          return-object
          searchable
          @input="selectStock"
        >
          <template v-slot:item="{ item }">
            <div class="pt-1 pb-1">
              <div>
                {{ item.ticker }}
              </div>

              <div class="text-subtitle-2 grey--text">
                {{ item.name }}
              </div>
            </div>
          </template>

          <template v-slot:prepend-item>
            <div class="pa-3" style="position: sticky; top: 0; background-color: #fff; z-index: 2">
              <div class="mb-3">
                <v-text-field v-model="searchedTicker" label="Поиск" clearable hide-details @input="fetchStocks" />
              </div>
            </div>
          </template>
        </v-select>

        <div class="d-flex">
          <v-btn type="submit" color="primary mr-4" @click="changeTicker"> Сохранить </v-btn>

          <v-btn type="submit" color="gray" @click="isChangeTicker = false"> Отмена </v-btn>
        </div>
      </div>
    </v-col>

    <v-col class="font-weight-regular" md="12">
      <v-checkbox v-model="indexableDescriptionProxy" class="my-0" label="Индексация описания" hide-details />
    </v-col>

    <v-col class="font-weight-regular" md="12">
      <v-checkbox v-model="inSpbeProxy" class="my-0" label="Доступно на СПБ" hide-details />
    </v-col>

    <v-col md="2">ISIN</v-col>
    <v-col md="10"><v-text-field :value="stock.isin" label="ISIN" dense @input="setField(`isin`, $event)" /></v-col>
  </v-row>
</template>

<script>
import { ApiValidationError } from "~/core/api-validation-error";

import { editorFields } from "~/mixins/multi-editor/editor-fields";

import StocksStatus from "../../status";
import StocksSector from "../../sectors";
import StocksCountry from "../../country";

export default {
  mixins: [editorFields],
  props: {
    stock: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      currentLang: "ru",
      currentMobileLang: "ru",
      exchanges: [
        { mic: "EDGE", name: "BATS" },
        { mic: "XHKG", name: "Гонконгская биржа" },
        { mic: "XKAZ", name: "Казахстансая биржа" },
        { mic: "MISX", name: "Московская биржа" },
        { mic: "XNAS", name: "NASDAQ" },
        { mic: "XNYS", name: "Нью-Йоркская биржа" },
        { mic: "XOTC", name: "OTC Markets Group" },
        { mic: "SPBE", name: "СПБ Биржа" }
      ],
      typeStocks: [
        { uid: "cs", name: "Обыкновенная акция" },
        { uid: "ps", name: "Привилегированная акция" },
        { uid: "ad", name: "Депозитарная расписка (американская)" },
        { uid: "gd", name: "Депозитарная расписка (глобальная)" },
        { uid: "cef", name: "Фонд закрытого типа" },
        { uid: "oef", name: "Фонд открытого типа" },
        { uid: "et", name: "Торгуемый биржевой фонд" },
        { uid: "pif", name: "Паевой инвестиционный фонд" },
        { uid: "rt", name: "Right" },
        { uid: "struct", name: "Структурный продукт" },
        { uid: "ut", name: "Unit" },
        { uid: "wi", name: "When Issued" },
        { uid: "wt", name: "Варрант" },
        { uid: "", name: "Прочее" }
      ],
      isChangeTicker: false,
      changedTicker: {
        oldTicker: "",
        newTicker: ""
      },
      stocks: [],
      searchedTicker: ""
    };
  },
  computed: {
    split() {
      return this.stock.splits[0];
    },
    descriptionLanguages() {
      return Object.keys(this.stock.description);
    },

    mobileDescriptionLanguages() {
      return Object.keys(this.stock.mobileDescription);
    },

    indexableDescriptionProxy: {
      get() {
        return this.stock.indexableDescription;
      },
      set(value) {
        this.setField("indexableDescription", value);
      }
    },
    inSpbeProxy: {
      get() {
        return this.stock.inSpbe;
      },
      set(value) {
        this.setField("inSpbe", value);
      }
    }
  },
  watch: {
    "stock.status.value"(value) {
      if (value !== "ticker_changed") {
        this.setField("status.newTicker", null);
      }
    }
  },
  methods: {
    async changeTicker() {
      if (!this.changedTicker.newTicker) {
        this.$snackbar.error("Введите новый тикер");

        return;
      }

      this.changedTicker.oldTicker = this.stock.ticker;

      try {
        const stock = await this.$axios.$post("stocks/change-ticker", this.changedTicker);

        this.stocks = [];

        this.searchedTicker = "";

        this.isChangeTicker = false;

        this.changedTicker = { oldTicker: "", newTicker: "" };

        this.$snackbar.success("Тикер изменен");

        this.$emit("update-ticker", stock);
      } catch (e) {
        const html = new ApiValidationError(e, Object.keys(this.changedTicker)).toHtml();

        this.$snackbar.error("Не удалось изменить тикер: " + html);
      }
    },

    async fetchStocks(value) {
      if (!value) {
        this.stocks = [];

        return;
      }

      try {
        const stocks = await this.$axios.$get(`stocks`, {
          params: { mode: "search", title: value }
        });

        this.stocks = stocks?.data ?? [];
      } catch (e) {
        this.$snackbar.error("Не удалось найти тикер");

        this.stocks = [];
      }
    },
    selectStock(stock) {
      this.changedTicker.newTicker = stock.ticker;
    }
  },
  components: {
    StocksStatus,
    StocksSector,
    StocksCountry
  }
};
</script>
