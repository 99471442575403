var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"pa-3"},[_c('data-table',{ref:"dataTable",attrs:{"url":"stocks/splits","headers":_vm.headers,"table-params":_vm.tableParams},scopedSlots:_vm._u([{key:"item.date",fn:function(ref){
var split = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("date")(split.date))+" ")]}},{key:"item.from",fn:function(ref){
var split = ref.item;
return [_vm._v(" "+_vm._s(_vm.formateNumber(split.from))+":"+_vm._s(_vm.formateNumber(split.to))+" ")]}},{key:"item.source",fn:function(ref){
var split = ref.item;
return [_vm._v(" "+_vm._s(_vm.sourceMap[split.source])+" ")]}},{key:"item.createdAt",fn:function(ref){
var split = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("dateTime")(split.createdAt))+" ")]}},{key:"item.status",fn:function(ref){
var split = ref.item;
return [_vm._v(" "+_vm._s(_vm.statusMap[split.status])+" ")]}},{key:"item.actions",fn:function(ref){
var split = ref.item;
return [_c('div',{staticClass:"d-flex"},[_c('v-btn',{attrs:{"icon":"","color":"accent"},on:{"click":function($event){return _vm.openEditedSplit(split)}}},[_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-pencil")])],1),_c('v-btn',{attrs:{"icon":"","color":"error"},on:{"click":function($event){return _vm.deleteSplit(split)}}},[_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-delete")])],1)],1)]}}],null,true)}),_c('edit-split-modal',{attrs:{"is-open-modal":_vm.isOpenModal,"fields-split":_vm.fieldsSplit},on:{"close":_vm.resetSplitFields,"change-field":_vm.changeField,"update":_vm.updateSplit}}),_c('v-divider',{staticClass:"my-6"}),_c('div',{staticClass:"text-h6 mb-3"},[_vm._v("Новый сплит")]),_c('create-split-form',{attrs:{"ticker":_vm.ticker}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }